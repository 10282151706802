import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {
  Avatar,
  CircularProgress,
  Grid,
  Paper,
  Rating,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { API_URL } from "../../Apis/config";
import { GrClose } from "react-icons/gr";
import { useState } from "react";
import { useEffect } from "react";
import { getSwapProviderHistory } from "../../Apis/order_api";
import { dateFormateHHMMA, dateFormateMMDDYYY } from "../../Service/DateSevice";
import { ArrowBack, ArrowBackIos } from "@mui/icons-material";

export default function SwappedDailog({ id, handleClose, open, service_name }) {
  const [data, setData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const getData = async () => {
    setIsLoading(true);
    const res = await getSwapProviderHistory(id);

    if (res?.s) {
      setData(res?.r ?? []);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        sx={{
          width: "100vw",
          backdropFilter: "blur(3px)",
          bgcolor: "rgba(105, 55, 0, 0.230)",
          display: "flex",
          justifyContent: "flex-end",
        }}
        onClose={handleClose}
      >
        <Stack
          direction="row"
          bgcolor="#F6F6F6"
          paddingY="0.450rem"
          paddingX="0.6rem"
          mx="1rem"
          mt="0.6rem"
          borderRadius="5px"
          justifyContent="space-between"
          alignItems="center"
          mb={1}
        >
          <Typography fontSize="18px" fontWeight="500">
            Swapped History
          </Typography>
          <GrClose
            style={{ fontSize: "1rem", cursor: "pointer" }}
            onClick={handleClose}
          />
        </Stack>
        <Typography fontSize="18px" fontWeight="500" sx={{ mx: "1.3rem" }}>
          {service_name}
        </Typography>
        <DialogContent
          sx={{
            height: "100vh",
            width: "500px",
            paddingTop: "15px",
            flex: 1,
            overflowY: "auto",
          }}
        >
          {isLoading ? (
            <CircularProgress />
          ) : data.length ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <UnavailableView data={data} handleSelected={() => { }} />
            </Box>
          ) : (
            <Box>
              <Typography>No data available</Typography>
            </Box>
          )}
        </DialogContent>
        {/* <DialogActions>
          <ButtonView
            lable="Close"
            fontSize="1.130rem"
            fontWeight="500"
            sx={{ py: "0.6rem", width: "100%" }}
            onClick={() => {
              handleClose(0);
            }}
          />
        </DialogActions> */}
      </Dialog>
    </>
  );
}

function UnavailableView({ data, handleSelected }) {
  return !data.length ? null : (
    <Box>
      <DetailsView data={data} handleSelected={handleSelected} />
    </Box>
  );
}

function DetailsView({ data, handleSelected }) {
  return (
    <Grid container columns={3} columnGap={1.5} rowGap={1.5}>
      {data.map((v, i) => {
        return (
          <ItemView key={v.user_id} v={v} handleSelected={handleSelected} />
        );
      })}
    </Grid>
  );
}

function ItemView({ v, handleSelected }) {
  console.log('v', v);

  return (
    <Box
      sx={{
        backgroundColor: "#c0c0c02a",
        width: "100%",
        p: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <Paper
          component={"div"}
          elevation={0}
          onClick={() => {
            handleSelected(v);
          }}
          sx={{
            overflow: "hidden",
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
            width: "9rem",
            height: "11rem",
            p: 2,
            boxShadow: "3px 3px 9px 0px #00000040",
            backgroundColor: false && "#ffeac9",
          }}
        >
          <Stack width="100%" flexDirection="row" justifyContent="center">
            <Box width="90%" display="flex" justifyContent="center">
              <Avatar
                sx={{ height: "60px", width: "60px" }}
                src={`${API_URL.baseUrl +
                  API_URL.userProfileImage +
                  v.old_user_photo_thumb
                  }`}
              />
            </Box>
          </Stack>

          <Typography
            sx={{ fontSize: "13.5px", wordWrap: "break-word", mt: "0.3rem" }}
          >
            Podian ID: {v?.old_user_id ?? ""}
          </Typography>
          <Typography
            sx={{ fontSize: "13.5px", wordWrap: "break-word", mt: "0.3rem" }}
          >
            {v.old_user_name ?? ""}
          </Typography>
          <Box
            sx={{
              width: "100%",
            }}
          >
            ⭐{v.old_user_rate.toFixed(1)}
          </Box>
          <Typography sx={{ fontSize: "0.7rem", wordWrap: "break-word" }}>
            {v.old_user_mobile}
          </Typography>
        </Paper>
        <ArrowBack color="#00000053" />
        {/* {New user} */}
        <Paper
          component={"div"}
          elevation={0}
          onClick={() => {
            handleSelected(v);
          }}
          sx={{
            overflow: "hidden",
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
            width: "9rem",
            height: "11rem",
            p: 2,
            boxShadow: "3px 3px 9px 0px #00000040",
            backgroundColor: false && "#ffeac9",
          }}
        >
          <Stack width="100%" flexDirection="row" justifyContent="center">
            <Box width="90%" display="flex" justifyContent="center">
              <Avatar
                sx={{ height: "60px", width: "60px" }}
                src={`${API_URL.baseUrl +
                  API_URL.userProfileImage +
                  v.new_user_photo_thumb
                  }`}
              />
            </Box>
          </Stack>
          <Typography
            sx={{ fontSize: "13.5px", wordWrap: "break-word", mt: "0.3rem" }}
          >
            Podian ID:{v.new_user_id ?? ""}
          </Typography>
          <Typography
            sx={{ fontSize: "13.5px", wordWrap: "break-word", mt: "0.3rem" }}
          >
            {v.new_user_name ?? ""}
          </Typography>
          <Box
            sx={{
              width: "100%",
            }}
          >
            ⭐{v.new_user_rate.toFixed(1)}
          </Box>
          <Typography sx={{ fontSize: "0.7rem", wordWrap: "break-word" }}>
            {v.new_user_mobile}
          </Typography>
        </Paper>
      </Box>

      <Box mt={2} />
      <RowView title="Admin Name" value={v.status_by_user_name} />
      <RowView
        title="Date"
        value={v.updated_at ? dateFormateMMDDYYY(v.updated_at) : "-"}
      />
      <RowView
        title="Time"
        value={v.updated_at ? dateFormateHHMMA(v.updated_at) : "-"}
      />
      <RowView title="Reason" value={v.swap_msg} />
    </Box>
  );
}

function RowView({ title, value }) {
  return (

    <Box
      sx={{
        display: "flex",
        mt: 0.5
      }}
    >
      <Typography
        sx={{ width: "100px", fontSize: "13px" }}
      >
        {title}
      </Typography>
      <Typography sx={{ fontSize: "13px" }}>:  {value}</Typography>
    </Box>

  );
}
