import React from "react";
import { Popover, Stack, Tooltip, Typography } from "@mui/material";
import { GrClose } from "react-icons/gr";
import DataGrideView from "../../../../../Component/DataGrideView/DataGrideView";
import DateMDYHMA from "../../../../../Component/Date/DateMDYHMA";

const paymentColumn = [
  {
    field: "id",
    headerName: "Sr.",
    filterable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return params.api.getRowIndex(params.row.id) + 1;
    },
  },

  {
    field: "amount",
    headerName: "Price",
    width: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return params.row.type == 1 ? (
        <Typography>{`₹${params.value}`}</Typography>
      ) : (
        <Typography>{`₹${params.row.refund}`}</Typography>
      );
    },
  },
  {
    field: "method",
    headerName: "Method",
    type: "string",
    width: 200,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "transaction_id",
    headerName: "Transaction ID",
    type: "string",
    width: 250,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      const value = params.value || params.row.cca_tracking_id || params.row.payment_id || params.row.cca_bank_ref_no || "-";
      
      return (
        <Tooltip 
          title={value.length > 150 ? value : ""}
          arrow
          placement="top-start"
          enterDelay={500}
          leaveDelay={200}
        >
          <Typography
            sx={{
              width: '100%',
              wordBreak: 'break-all',
              whiteSpace: 'normal',
              lineHeight: '1.2em',
              maxHeight: '3.6em',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 5,
              WebkitBoxOrient: 'vertical',
              textAlign: 'center',
            }}
          >
            {value}
          </Typography>
        </Tooltip>
      );
    },
  },
  {
    field: "note",
    headerName: "Note",
    type: "string",
    width: 250,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      const value = params.value || "-";
      const displayValue = value.length > 150 ? `${value.substring(0, 150)}...` : value;
      return (
        <Tooltip title={value.length > 150 ? value : ""} arrow>
          <Typography sx={{
            width: '100%',
            wordBreak: 'break-all',
            whiteSpace: 'normal',
            lineHeight: '1.2em',
            maxHeight: '3.6em',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 5,
            WebkitBoxOrient: 'vertical',
            textAlign: 'center',
          }}>
            {displayValue}
          </Typography>
        </Tooltip>
      );
    },
  },
  {
    field: "created_at",
    headerName: "Booking Date",
    type: "string",
    width: 200,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return <DateMDYHMA date={params.value} />;
    },
  },
  {
    field: "status",
    headerName: "Status",
    type: "number",
    headerAlign: "center",
    align: "center",
    flex:1,
    renderCell: (params) => (
      <Typography
        color={params.row.type > 0 ? "#0DA800" : "red"}
        fontSize="12.4px"
        fontWeight="500"
      >
        {params.row.status > 0
          ? "Success"
          : params.row.status == 0
          ? "Processing"
          : params.row.status < 0
          ? "Faild"
          : "REJECTED"}
      </Typography>
    ),
  },
];

const TransactionDetailsModal = ({
  open,
  anchorEl,
  handleClose,
  cols,
  rows,
}) => {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      sx={{
        backdropFilter: "blur(3px)",
        backgroundColor: "#1C1000B2",
        height: "100vh",
      }}
    >
      <Stack
        sx={{
          width: "63vw",
          p: 1.9,
          gap: 2,
          bgcolor: "white",
          borderRadius: "5px",
        }}
      >
        <Stack
          direction="row"
          bgcolor="#F6F6F6"
          paddingY="0.450rem"
          paddingX="0.6rem"
          borderRadius="5px"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography fontSize="18px" fontWeight="500">
            Transaction History
          </Typography>
          <GrClose
            style={{ fontSize: "1rem", cursor: "pointer" }}
            onClick={handleClose}
          />
        </Stack>

        <DataGrideView
          rows={rows}
          columns={paymentColumn}
          colHeight={"3.4rem"}
          columnColor="#F6F6F6"
          rowsLimit={rows?.length ?? 0}
          hideFooter={true}
          sx={{ height: "83.8vh" }}
        />
      </Stack>
    </Popover>
  );
};

export default TransactionDetailsModal;
