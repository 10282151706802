import { GETAPI, API_URL, POSTAPI } from "./config";

export const getWatingForUser = async (count) => {
  const data = await GETAPI(API_URL.getWaitingForAdmin, "&count=" + count);

  return data;
};

export const getForAdmin = async (
  count,
  status,
  dateRange,
  search,
  p = 0,
  serviceType = 0
) => {
  let query = "";

  if (status) {
    query += "&status=" + status;
  }

  query += "&service_type=" + serviceType;

  if (search) {
    query += "&search=" + search;
  }

  if (p != null) {
    query += "&paid=" + p;
  }

  if (dateRange && Object.keys(dateRange).length) {
    query += "&from_date=" + dateRange.start;
    query += "&to_date=" + dateRange.end;
  }
  const data = await GETAPI(API_URL.getForAdmin, "&count=" + count + query);
  return data;
};

export const getForAdminNew = async (count) => {
  let query = "";
  query += "&status=" + 0;

  const data = await GETAPI(API_URL.getForAdmin, "&count=" + count + query);
  return data;
};

export const getIssueOrder = async (count, status) => {
  let query = "";

  if (status) {
    query += "&status=" + status;
  }

  if (count) {
    query += "&count=" + count;
  }

  const data = await GETAPI(API_URL.getAllOIssues, query);
  return data;
};

export const getOrderDetailsById = async (id) => {
  const data = await GETAPI(API_URL.getOrderById, "&orders_id=" + id);

  return data;
};

export const deleteOrderCouponById = async (id) => {
  const data = await GETAPI(API_URL.deleteOrderCouponById, "&id=" + id);

  return data;
};

export const getDetailsOfProviderToOrderItems = async (id, status = null) => {
  let query = "";
  if (status != null) {
    query = "&status=" + status;
  }
  const data = await GETAPI(
    API_URL.getProviderOfOrder,
    "&orders_items_id=" + id + query
  );

  return data;
};

export const getDetailsOfProviderUnavailable = async (id, count, search) => {
  let query = "";
  if (search) {
    query = "&search=" + search;
  }
  const data = await GETAPI(
    API_URL.getUnAvailableProvidersOfService,
    "&orders_items_id=" + id + query + "&count=" + count
  );

  return data;
};

export const getDetailsOfOtherProviderToOrderItems = async (
  id,
  search,
  count
) => {
  let query = "";
  if (search) {
    query = "&search=" + search;
  }
  const data = await GETAPI(
    API_URL.getOtherProvidersOfService,
    "&orders_items_id=" + id + query + "&count=" + count
  );

  return data;
};

export const orderItemApproveDisApprove = async (approve, orders_items_id) => {
  let query = "";

  if (approve) {
    query +=
      "&approve_user_ids=" + approve + "&orders_items_id=" + orders_items_id;
  }
  // if (disApprove) {
  //   query += "&disapprove=" + disApprove;
  // }
  const data = await GETAPI(API_URL.orderItemApproveDisApprove, query);
  return data;
};

export const submitEditedLink = async (id, link) => {
  const formData = new FormData();
  formData.append("orders_id", id);
  formData.append("link", JSON.stringify(link));
  const data = await POSTAPI(API_URL.submitEditedLink, formData);

  return data;
};

export const addOrder = async (data) => {
  const formData = new FormData();
  formData.append("user_id", data.user_id);
  formData.append("city_id", data.city_id);
  formData.append("date_time", data.date_time);
  formData.append("category_id", data.category_id);
  formData.append("sub_category_id", data.sub_category_id);
  formData.append("address", data.address);
  formData.append("latitude", data.latitude);
  formData.append("longitude", data.longitude);
  formData.append("shoot_area", data.shoot_area);
  formData.append("isCollab", data.isCollaboration ? 1 : 0);
  formData.append(
    "is_repeat_previous_provider",
    data.isRepeatPreviousProvider ? 1 : 0
  );
  formData.append("is_coin_redeem", data.isCoinRedeem ? 1 : 0);

  if (data.remarks !== "" && data.remarks) {
    formData.append("remarks", data.remarks);
  }
  if (data.area) {
    formData.append("area", data.area);
  }

  if (data.orders_id) {
    formData.append("orders_id", data.orders_id);
  }

  if (data.sub_category_name) {
    formData.append("sub_category_name", data.sub_category_name);
  }

  if (data.near_by_address) {
    formData.append("near_by_address", data.near_by_address);
  }

  if (data.address1) {
    formData.append("address1", data.address1);
  }

  if (data.gst_no) {
    formData.append("gst_no", data.gst_no);
  }

  if (data.business_name) {
    formData.append("business_name", data.business_name);
  }

  if (data.billing_address) {
    formData.append("billing_address", data.billing_address);
  }

  formData.append("is_for_someone", data.is_for_someone);

  if (data.someone_name) {
    formData.append("someone_name", data.someone_name);
  }

  if (data.someone_mobile) {
    formData.append("someone_mobile", data.someone_mobile);
  }
  if (data.isNotify) {
    formData.append("isNotify", data.isNotify);
  }
  formData.append("is_pod_can_use", data.is_pod_can_use);
  const res = await POSTAPI(
    data.orders_id ? API_URL.updateOrder : API_URL.draftOrder,
    formData
  );

  return res;
};

export const resudleOrderOrder = async (data) => {
  const formData = new FormData();

  formData.append("date_time", data.date_time);

  formData.append("orders_id", data.orders_id);

  const res = await POSTAPI(API_URL.rescheduleOrder, formData);

  return res;
};

export const confirmOrder = async (id, isNotify, s) => {
  const n = isNotify ? "&isNotify=" + 1 : "&isNotify=" + 0;
  // const n = isNotify ? (isNotify ? "&isNotify=" + 1 : "&isNotify=" + 0) : "";
  console.log('n',);
  
  const data = await GETAPI(API_URL.confirmOrder, "&orders_id=" + id + n);
  console.log('data',data);
  
  return data;
};

export const cancelOrder = async (id, r) => {
  const data = await GETAPI(
    API_URL.cancelOrder,
    "&orders_id=" + id + "&reason=" + r
  );
  return data;
};

export const removedTransport = async (id, status) => {
  let query = `&isRemoveTransportCharge=${status ? 0 : 1}`;
  const data = await GETAPI(
    API_URL.removeTransportApi,
    "&orders_id=" + id + query
  );
  return data;
};

export const removedTransportProvider = async (id, status) => {
  let query = `&orders_items_providers_ids=${status}`;
  const data = await GETAPI(
    API_URL.removeTransportChargeProvider,
    "&orders_id=" + id + query
  );
  return data;
};

export const notifyAgain = async (id, order_items_id) => {
  let query = "";
  if (order_items_id) {
    query = " &order_item_ids=" + order_items_id;
  }
  const data = await GETAPI(API_URL.notifyAgainApi, "&orders_id=" + id + query);
  return data;
};

export const statusChange = async (id, status) => {
  const formData = new FormData();
  formData.append("orders_items_providers_id", id);
  formData.append("status", status);
  const data = await POSTAPI(API_URL.updateProviderStatus, formData);
  return data;
};

export const chnagePaymentRecord = async (
  id,
  amount,
  isRefund,
  method,
  note,
  transaction_id
) => {
  const formData = new FormData();
  formData.append("orders_id", id);
  formData.append("amount", amount);
  formData.append("method", method);
  formData.append("isRefund", isRefund ? 1 : 0);
  formData.append("note", note);

  if (transaction_id) {
    formData.append("transaction_id", transaction_id);
  }

  const data = await POSTAPI(API_URL.addPaymentRecord, formData);

  return data;
};

export const getDetailsByGst = async (gst) => {
  let query = `&gstin=${gst}`;
  const data = await GETAPI(API_URL.getGstDetailsByNo, query);
  return data;
};

export const addInvoicePaymentRecord = async (
  id,
  amount,
  isRefund,
  method,
  note,
  transaction_id
) => {
  const formData = new FormData();
  formData.append("orders_invoice_id", id);
  formData.append("amount", amount);
  formData.append("method", method);
  formData.append("isRefund", isRefund ? 1 : 0);
  formData.append("note", note);

  if (transaction_id) {
    formData.append("transaction_id", transaction_id);
  }

  const data = await POSTAPI(API_URL.addInvoicePaymentRecord, formData);

  return data;
};

export const swapProvider = async (data) => {
  const formData = new FormData();

  formData.append("orders_items_providers_id", data.orders_items_providers_id);

  formData.append("user_id", data.user_id);
  formData.append("message", data.message);

  const res = await POSTAPI(API_URL.swapProvider, formData);

  return res;
};

export const updateProviderPrice = async (data) => {
  const formData = new FormData();

  formData.append("orders_items_providers_id", data.providerID);
  formData.append("price_per_hour", data.pricePerHour);

  const res = await POSTAPI(API_URL.updateProviderPrice, formData);
  return res;
};

export const getSwapProviderHistory = async (id) => {
  const data = await GETAPI(
    API_URL.getSwapProviderHistory,
    "&orders_items_providers_id=" + id
  );

  return data;
};

export const ADD_ORDER_SERVICE = async (data) => {
  const formData = new FormData();
  formData.append("orders_id", data.orders_id);
  formData.append("service_id", data.service_id);

  data.qty && formData.append("qty", data.qty);

  data.hours && formData.append("hours", data.hours);

  data.remarks && formData.append("remarks", data.remarks);

  data.provider_cut && formData.append("provider_cut", data.provider_cut);

  data.report_time && formData.append("report_time", data.report_time);

  data.audio && formData.append("audio", data.audio);

  data.photo && formData.append("photo", data.photo);

  data.photoEdited && formData.append("photoEdited", data.photoEdited);

  const res = await POSTAPI(API_URL.addOrderService, formData);
  return res;
};

export const ADD_UPDATE_ORDER_DISCOUNT = async (data) => {
  const formData = new FormData();
  formData.append("orders_id", data.orders_id);

  data.discount_by_admin &&
    formData.append("discount_by_admin", data.discount_by_admin);
  data.discount_by_label &&
    formData.append("discount_by_label", data.discount_by_label);

  const res = await POSTAPI(API_URL.addUpdateOrderDiscount, formData);
  return res;
};

export const UPDATE_ORDER_ADDRESS = async (data) => {
  const formData = new FormData();

  formData.append("orders_id", data.orders_id);
  formData.append("address", data.address);
  formData.append("latitude", data.latitude);
  formData.append("longitude", data.longitude);
  formData.append("area", data.area);
  formData.append("near_by_address", data.near_by_address);
  formData.append("address1", data.address1);

  const res = await POSTAPI(API_URL.changeAddress, formData);
  return res;
};

export const GET_SUGGESTED_PROVIDER = async (id) => {
  const res = await GETAPI(
    API_URL.getSuggestedProvider,
    "&orders_items_id=" + id
  );
  return res;
};

export const PAY_BY_COIN_WALLET = async (data) => {
  const formData = new FormData();

  if (data.ordersInvoiceId) {
    formData.append("orders_invoice_id", data.ordersInvoiceID);
  }

  if (data.orderID) {
    formData.append("orders_id", data.orderID);
  }

  if (data.userID) {
    formData.append("user_id", data.userID);
  }

  const res = await POSTAPI(API_URL.payByCoinWallet, formData);
  return res;
};
