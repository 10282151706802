export const getPaymentStatus = (params) => {
  return getPStatus(params.row.paid_amount, params.row.total_amount);
};

export const getPStatus = (finalAmount) => {
  
  return +finalAmount == 0 ? "Paid" : finalAmount < 0 ? "Refund" : "UnPaid";
};

export const getColor = (value) => {
  if (value == 0) {
    return "#9e60f5";
  } else if (value == 1) {
    return "#ebb11f";
  } else if (value == 2) {
    return "#e6c1c3";
  } else if (value == 3) {
    return "#0e8826";
  } else if (value == 4) {
    return "#4991e9";
  } else if (value == 5) {
    return "#1a393a";
  } else if (value == 6) {
    return "#e459ee";
  } else if (value == 7) {
    return "#95ab09";
  } else if (value == -1) {
    return "#e65932";
  }
};

export const getStatus = (value) => {
  if (value == 0) {
    return "Draft";
  } else if (value == 1) {
    return "New Order";
  } else if (value == 2) {
    return "Intersted";
  } else if (value == 3) {
    return "Confirmed";
  } else if (value == 4) {
    return "Started";
  } else if (value == 5) {
    return "Finished";
  } else if (value == 6) {
    return "Raw Received";
  } else if (value == 7) {
    return "Delivered";
  } else if (value == -1) {
    return "Canceled";
  }
};
