import { useState } from "react";
import { Box, Button, Stack, Tooltip, Typography } from "@mui/material";
import InfoIcon from "../../../../../Assets/InfoIcon.png";
import ButtonIcon from "../../../../../Component/Button/ButtonIcon";
import change_icon from "../../../../../Assets/Icons for pages/change_icon.png";
import add_button from "../../../../../Assets/add_button.png";
import board_icon from "../../../../../Assets/Icons for pages/board_icon.png";
import TransactionDetailsModal from "./TransactionDetailsModal";
import AddPaymentRecord from "./AddPaymentRecord";
import { rightsUser } from "../../../../Home/Drawer/DrawerView";

const PaymentDetailsBox = ({
  id,
  isPaymentPaid,
  totalAmount,
  finalAmount,
  distanceCharges,
  distanceCharges_desc,
  sgst,
  cgst,
  netAmount,
  transactionDetails_rows,
  isRemoveTransport,
  handleRemovedTransport,
  paidAmount,
  getData,
  data,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openAddPaymentRecord, setOpenAddPaymentRecord] = useState(false);
  const handleOpenPopOver = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopOver = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const modalId = open ? "simple-popover" : undefined;

  const getAmountFR = (amount) => {
    return `₹${amount.toFixed(2)}`;
  };

  const changePaymentRecordClose = (v) => {
    setOpenAddPaymentRecord(false);
    if (v) {
      getData();
    }
  };

  return (
    <>
      <Box
        height="100%"
        bgcolor="white"
        borderRadius="5px"
        boxShadow="3px 3px 7px 0px #00000040"
        paddingBottom="0.530rem"
      >
        {/*Header*/}
        <Box
          sx={{
            width: "100%",
            bgcolor: isPaymentPaid === "Paid" ? "#0DA80033" : "#E5000033",
            height: "8%",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography fontSize="15px" fontWeight="500" ml="0.8rem">
            Payment Details
          </Typography>
          <Typography
            fontSize="12px"
            color={isPaymentPaid === "Paid" ? "#0DA800" : "#E50000"}
            fontWeight="600"
            mr="0.8rem"
          >
            {data?.refund_amount > 0 ? "Refunded" : data?.paid_amount > 0 && data?.finalAmount > 0 ? 'Partial Paid' : isPaymentPaid.toUpperCase()}
          </Typography>
        </Box>

        {rightsUser.includes(5) ? (
          <Stack height="92%">
            <Stack paddingX="0.8rem" mt="0.3rem">
              {/*Total Amount*/}
              <Box
                display="flex"
                height="2rem"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" alignItems="center" gap="3px">
                  <Typography fontSize="13px">Sub Total</Typography>
                  <Tooltip title="Total price of services that customer ordered.">
                    <img
                      src={InfoIcon}
                      style={{
                        objectFit: "fill",
                        width: "10.5px",
                        height: "10.5px",
                      }}
                    />
                  </Tooltip>
                </Box>
                <Typography fontSize="12.5px" fontWeight="500">
                  {getAmountFR(data.sub_total)}
                </Typography>
              </Box>

              {data?.charges?.map((v) => {
                return (
                  <Box
                    key={v.id}
                    display="flex"
                    height="2rem"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Stack direction="row" alignItems="center" gap="0.5rem">
                      <Typography fontSize="13px">
                        {v.description === "Discount by POD"
                          ? v.description
                          : v.name}
                      </Typography>

                      <Tooltip
                        title={
                          v.description === "Discount by POD"
                            ? v.name
                            : v.description
                        }
                      >
                        <img
                          src={InfoIcon}
                          style={{
                            objectFit: "fill",
                            width: "10.5px",
                            height: "10.5px",
                          }}
                        />
                      </Tooltip>
                    </Stack>
                    <Typography fontSize="12.5px" fontWeight="500">
                      {getAmountFR(v.amount)}
                    </Typography>
                  </Box>
                );
              })}
            </Stack>

            <Box
              width="100%"
              height="100%"
              display="flex"
              paddingRight="0.8rem"
              paddingTop="0.8rem"
              justifyContent="flex-end"
            >
              {paidAmount > 0 ? null : (
                <Button
                  onClick={() => handleRemovedTransport(isRemoveTransport)}
                  style={{
                    padding: "0px",
                    height: "0px",
                    textTransform: "none",
                  }}
                >
                  <Typography
                    color="#E50000"
                    sx={{
                      fontSize: "14px",
                      color: "#E50000",
                      textDecoration: "underline",
                      fontWeight: "500",
                      cursor: "pointer",
                      "&:hover": {
                        color: "red",
                      },
                    }}
                  >
                    {isRemoveTransport ? "Add Transport" : "Remove Transport"}
                  </Typography>
                </Button>
              )}
            </Box>

            <Stack>
              {/*Final Amount*/}
              <Box
                display="flex"
                height="2.3rem"
                justifyContent="space-between"
                alignItems="center"
                paddingX="0.8rem"
                borderBottom="1px solid #EFEFEF"
              >
                <Typography fontSize="13px">Final Amount</Typography>
                <Box display="flex">
                  <Typography fontSize="12.5px" fontWeight="500">
                    {getAmountFR(data.total_amount)}
                  </Typography>
                </Box>
              </Box>

              {/*Net Payable Amount*/}
              <Box
                display="flex"
                height="2.3rem"
                justifyContent="space-between"
                alignItems="center"
                paddingX="0.8rem"
              >
                <Typography fontSize="13px">Net Payable Amount</Typography>
                <Box display="flex">
                  <Typography fontSize="12.5px" fontWeight="500">
                    {getAmountFR(data.finalAmount)}
                  </Typography>
                </Box>
              </Box>

              {/*Button*/}
              <Box px="0.8rem">
                {isPaymentPaid === "Paid" || data.paid_amount > 0 ? (
                  <Stack gap="0.5rem">
                    <ButtonIcon
                      label="ADD PAYMENT RECORD"
                      bgcolor="#FFAB30"
                      height="2.6rem"
                      icon={add_button}
                      imageSize="10px"
                      fontSize="14.2px"
                      width="100%"
                      gap="0.5rem"
                      onClick={() => setOpenAddPaymentRecord(true)}
                    />

                    <ButtonIcon
                      label="TRANSACTION DETAILS"
                      bgcolor="#FFAB30"
                      height="2.6rem"
                      icon={board_icon}
                      imageSize="13px"
                      fontSize="14.2px"
                      width="100%"
                      gap="0.5rem"
                      onClick={handleOpenPopOver}
                    />
                  </Stack>
                ) : (
                  <ButtonIcon
                    label="CHANGE PAYMENT RECORD"
                    bgcolor="#FFAB30"
                    height="2.6rem"
                    icon={change_icon}
                    imageSize="13px"
                    fontSize="14.2px"
                    width="100%"
                    gap="0.5rem"
                    onClick={() => setOpenAddPaymentRecord(true)}
                  />
                )}
              </Box>
            </Stack>
          </Stack>
        ) : null}
      </Box>

      {/*Transaction Details Modal*/}
      <TransactionDetailsModal
        open={open}
        handleClose={handleClosePopOver}
        anchorEl={anchorEl}
        rows={transactionDetails_rows}
      />
      {/*Add Payment Record Modal*/}
      {openAddPaymentRecord && (
        <AddPaymentRecord
          open={openAddPaymentRecord}
          id={id}
          handleOnClose={changePaymentRecordClose}
        />
      )}
    </>
  );
};

export default PaymentDetailsBox;
