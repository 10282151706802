import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  GET_PENDDING_CALL_BACK_COUNT,
  GET_SUPPORT_CALLBACK_DONE,
  GET_SUPPORT_TICKETS_CLOSE,
  GET_SUPPORT_TICKETS_DETAILS,
  GET_SUPPORT_TICKETS_DETAILS_REPLIES,
  GET_SUPPORT_TICKETS_REOPEN,
  SUPPORTADDREPLY,
} from "../../../Apis/support_api";
import { API_URL } from "../../../Apis/config";
import DateMDYHMA from "../../../Component/Date/DateMDYHMA";
import SupportRepliesDailog from "../../../Component/Dailog/SupportRepliesDailog";
import ConfirmationDailog from "../../../Component/Dailog/ConfirmationDailog";
import { ToastShow } from "../../../Component/Toast/Toast";
import { Delete, Message, Photo, Send } from "@mui/icons-material";
import { useRef } from "react";
import Compressor from "compressorjs";
import { rightsUser } from "../../Home/Drawer/DrawerView";
import { format } from "date-fns";
import CancelDailog from "../../../Component/Dailog/OrderCancelDailog";

function TicketsDetails() {
  const { id } = useParams();
  const [row, setRow] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigator = useNavigate();
  const [open, setOpen] = useState(false);
  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const [openConfirmC, setOpenConfirmC] = React.useState(false);
  const [rowReply, setRowReply] = useState([]);
  const scrollRef = useRef();

  const [message, setMessage] = useState("");
  const [selectMedia, setSelectMedia] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [openC, setOpenC] = useState(false);
  const [selectedId, setSelectedId] = useState(0);

  const sendMessage = async () => {
    if (!message && !selectMedia.length) {
      ToastShow("Please enter the message");
    } else {
      setLoading(true);
      const data = await SUPPORTADDREPLY({ id, message, selectMedia });

      if (data && data.s) {
        setMessage("");
        setSelectMedia([]);
        getDataReplies();
      } else {
        ToastShow(data?.m ?? "Opps! something went wrong. Please try again.");
      }
      setLoading(false);
    }
  };

  const handleOnCloseConfirmation = async (v) => {
    if (v) {
      if (row?.status == 1) {
        const data = await GET_SUPPORT_TICKETS_CLOSE(row?.id ?? 0);
        if (data && data.s) {
          ToastShow("Success");
        } else {
          ToastShow(data?.m ?? "Opps! something went wrong. Please try again.");
        }
      } else {
        const data = await GET_SUPPORT_TICKETS_REOPEN(row?.id ?? 0);
        if (data && data.s) {
          ToastShow("Success");
        } else {
          ToastShow(data?.m ?? "Opps! something went wrong. Please try again.");
        }
      }

      getData();
    }
    setOpenConfirmation(false);
  };

  const handleCloseConfirmC = async (v, reason) => {
    if (v) {
      const data = await GET_SUPPORT_TICKETS_CLOSE(row?.id ?? 0, reason);
      if (data && data.s) {
        ToastShow("Success");
      } else {
        ToastShow(data?.m ?? "Opps! something went wrong. Please try again.");
      }

      getData();
    }
    setOpenConfirmC(false);
  };

  const handleCloseC = async (v) => {
    if (v) {
      const data = await GET_SUPPORT_CALLBACK_DONE(selectedId);
      if (data && data.s) {
        ToastShow("Success");
      } else {
        ToastShow(data?.m ?? "Opps! something went wrong. Please try again.");
      }

      getData();
    }
    setOpenC(false);
  };

  const getData = async () => {
    setIsLoading(true);
    const data = await GET_SUPPORT_TICKETS_DETAILS({ id });
    console.log('data',data);
    
    if (data && data.s) {
      setRow(data.r);
    }

    setIsLoading(false);
  };

  const getDataReplies = async () => {
    const data = await GET_SUPPORT_TICKETS_DETAILS_REPLIES({ id });
    if (data && data.s && data.r) {
      setRowReply(data.r);
    }
  };

  useEffect(() => {
    getData();
    getDataReplies();
  }, []);

  useEffect(() => {
    scrollRef?.current?.scrollIntoView({ behavior: "smooth" });
  }, [rowReply]);

  const getSatifactionValue = (v) => {
    if (v == 1) {
      return "Excellent";
    } else if (v == 2) {
      return "Good";
    } else if (v == 3) {
      return "Medium";
    } else if (v == 4) {
      return "Poor";
    } else if (v == 5) {
      return "Very Bad";
    } else if (v == "All") {
      return "-";
    } else {
      return "-";
    }
  };

  return (
    <Box>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Box
          sx={{
            flex: 1,
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mr: 1,
              alignItems: "center",
            }}
          >
            {/* <Button
              onClick={() => setOpen(true)}
              variant="contained"
              sx={{ alignItems: "flex-end", my: 1 }}
            >
              Reply
            </Button> */}

            {row.done_at ? (
              <Box>
                <Typography color="green">
                  Resolved on{" "}
                  {format(Date.parse(row.done_at), "dd-MMM-yyyy hh:mm a")}
                </Typography>
                {/* <Typography>Resolved By : {row.status_by_user_name}</Typography>
                <Typography>Note : {row.note}</Typography> */}
              </Box>
            ) : (
              <Box />
            )}

            <Button
              onClick={() => {
                if (row?.status == 1) {
                  setOpenConfirmC(true);
                } else {
                  setOpenConfirmation(true);
                }
              }}
              variant="contained"
              sx={{ alignItems: "flex-end", my: 1, mx: 1 }}
            >
              {row?.status == 1 ? "Close Ticket" : "Re-Open"}
            </Button>
          </Box>

          {/* <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: "10px",
            }}
          > */}
            {/* <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Ticket ID - #{id}
            </Typography> */}
            {/* <DateMDYHMA date={row.date_time} /> */}
          {/* </Box> */}

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              gap: 3,
              flex: 1,
              height: "90vh",
            }}
          >
            <Box
              sx={{
                flex: 1,
                maxHeight: "90vh",
                height: "100%",
                overflowY: "scroll",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mb: "10px",
                }}
              >
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  User Details
                </Typography>
                {/* <DateMDYHMA date={row.date_time} /> */}
              </Box>
              <Paper
                elevation={5}
                sx={{
                  p: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    alignItems: "center",
                  }}
                >
                  <Avatar
                    src={API_URL.baseUrl + API_URL.userProfileImage + row.photo}
                    sx={{
                      height: "80px",
                      width: "80px",
                      m: "20px",
                    }}
                  />
                  <Box>
                    <RowView
                      title="User Name"
                      width="90px"
                      value={row.user_name}
                    />
                    <RowView title="Email" width="90px" value={row.email} />
                    <RowView
                      title="Mobile No."
                      width="90px"
                      value={row.mobile}
                    />
                    <RowView title="Gender" width="90px" value={row.gender} />
                  </Box>
                </Box>
              </Paper>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h6" fontWeight="bold" mt={2} mb={1}>
                  Category
                </Typography>
              </Box>
              <Paper
                elevation={5}
                sx={{
                  p: 1,
                  mb: 2,
                }}
              >
                {/* <Divider sx={{ my: 1 }} /> */}

                <RowView title="Category" width="90px" value={row.category} />
                <RowView
                  title="Categories"
                  width="90px"
                  value={row.categories}
                />
              </Paper>

              {row.order ? (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Typography variant="h6" fontWeight="bold" sx={{ mb: 1 }}>
                      Order Details
                    </Typography>
                    {/* <Typography>#{row.order.id}</Typography> */}
                  </Box>
                  <Paper
                    elevation={5}
                    sx={{
                      p: 1,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    {/* <Divider
                    sx={{
                      my: 1,
                    }}
                  /> */}
                    {/* <RowView title="Order ID" value={"#" + row.order.id} /> */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          mr: 1,
                          fontSize: "16px",
                          fontWeight: "bold",
                          width: "120px",
                        }}
                        variant="h6"
                      >
                        Order ID
                      </Typography>
                      <Typography
                        onClick={() => {
                          if (rightsUser.includes(4)) {
                            navigator("/order/all/" + row.order.id);
                          }
                        }}
                        sx={{
                          fontSize: "16px",
                          color: "#F8B03F",
                          textDecoration: "underline",
                          cursor: rightsUser.includes(4)
                            ? "pointer"
                            : "default",
                        }}
                      >
                        {" "}
                        : {"#" + row.order.id}
                      </Typography>
                    </Box>
                    <RowView title="Category" value={row.order.category_name} />
                    <RowView
                      title="Sub Category"
                      value={row.order.sub_category_name}
                    />
                    <RowView title="Address" value={row.order.address} />
                    <RowView title="Area" value={row.order.area} />
                    {/* <Button
                      onClick={() => navigator("/order/all/" + row.order.id)}
                      variant="text"
                      sx={{ alignSelf: "flex-end" }}
                    >
                      more...
                    </Button> */}
                  </Paper>
                </>
              ) : null}

              {row.attachments?.length || row.details ? (
                <Box>
                  <Typography variant="h6" fontWeight="bold" mt={2} color="red">
                    Issue
                  </Typography>
                  <Paper
                    sx={{
                      p: 1,
                      mb: 1,
                    }}
                    elevation={5}
                  >
                   <Typography fontWeight="bold" color="red">
                    {row.details}
                   </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                    >
                      {row.attachments.map((v) => {
                        return (
                          <img
                            onClick={() =>
                              window.open(
                                API_URL.baseUrl +
                                  API_URL.supportTicketAttachmentURL +
                                  v.file
                              )
                            }
                            style={{
                              height: "200px",
                              width: "200px",
                              objectFit: "cover",
                              margin: "10px",
                              cursor: "pointer",
                            }}
                            key={v.id}
                            src={
                              API_URL.baseUrl +
                              API_URL.supportTicketAttachmentURL +
                              v.file
                            }
                          />
                        );
                      })}
                    </Box>
                  </Paper>
                </Box>
              ) : null}

              {row.satisfaction ? (
                <Box>
                  <Typography variant="h6" fontWeight="bold" mt={2}>
                    Satisfaction
                  </Typography>
                  <Paper elevation={5} sx={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "start",
                            flexDirection: "column",
                            p: 1,
                          }}>
                    <RowView
                      title="Satisfaction"
                      value={getSatifactionValue(row.satisfaction)}
                    />

                    <RowView
                      title="Satisfaction Comment"
                      value={row.satisfaction_comment}
                      width="200px"
                    />
                  </Paper>
                </Box>
              ) : null}

              {row.callback?.length ? (
                <Box>
                  <Typography variant="h6" fontWeight="bold" mt={2}>
                    Callback
                  </Typography>
                  <Paper elevation={5}>
                    {row.callback.map((v) => {
                      return (
                        <Box
                          key={v.id}
                          sx={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "start",
                            flexDirection: "column",
                            p: 1,
                          }}
                        >
                          {/* <RowView title="ID" value={"#" + v.id} /> */}

                          <RowView
                            title="Date"
                            value={
                              v.done_at
                                ? format(
                                    Date.parse(v.done_at),
                                    "dd-MMM-yyyy hh:mm a"
                                  )
                                : "-"
                            }
                          />
                          <RowView
                            title="Status"
                            value={v.status == 1 ? "Pending" : "Done"}
                          />
                          {v.status == 1 ? (
                            <Button
                              variant="outlined"
                              onClick={() => {
                                setSelectedId(v.id);
                                setOpenC(true);
                              }}
                            >
                              Resolve
                            </Button>
                          ) : null}

                          <Divider />
                        </Box>
                      );
                    })}
                  </Paper>
                </Box>
              ) : null}

              {row.statusTrack?.length ? (
                <Box mb={1}>
                  <Typography variant="h6" fontWeight="bold" mt={2}>
                    Status History
                  </Typography>
                  <Paper elevation={5}>
                    {row.statusTrack.map((v) => {
                      return (
                        <Box
                          key={v.id}
                          sx={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "start",
                            flexDirection: "column",
                            p: 1,
                          }}
                        >
                          {/* <RowView title="ID" value={"#" + v.id} /> */}
                          <RowView title="User Name" value={v.user_name} />
                          <RowView
                            title="Date"
                            value={format(
                              Date.parse(v.date_time),
                              "dd-MMM-yyyy hh:mm a"
                            )}
                          />
                          <RowView title="Note" value={v.note ?? "-"} />
                          <RowView
                            title="Status"
                            value={v.status == 2 ? "Resolve" : "Open"}
                          />
                          <Divider />
                        </Box>
                      );
                    })}
                  </Paper>
                </Box>
              ) : null}
            </Box>

            <Paper
              sx={{
                width: "25vw",
                backgroundColor: "white",
                height: "65vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                mr: 2,
                mt: 5,
              }}
              elevation={10}
            >
              <Box
                // ref={scrollRef}
                sx={{
                  flex: 1,
                  height: "80%",
                  overflowY: "auto",
                }}
              >
                {rowReply.map((v) => {
                  return (
                    <Box
                      key={v.id}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "start",
                        alignItems: "start",
                        p: 2,
                        pb: 1,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Avatar
                          sx={{
                            backgroundColor: "#c0c0c0",
                          }}
                          src={
                            API_URL.baseUrl +
                            API_URL.userProfileImage +
                            v.photo_thumb
                          }
                        />
                        <Box
                          sx={{
                            ml: 1,
                          }}
                        >
                          <Typography
                            sx={{ fontSize: "12px", fontWeight: "bold" }}
                          >
                            {v.user_name}
                          </Typography>
                          <Typography sx={{ fontSize: "10px" }}>
                            {v.date_time}
                          </Typography>
                        </Box>
                      </Box>

                      <Typography
                        sx={{
                          fontSize: "12px",
                          ml: 6,
                          wordBreak: "break-word",
                        }}
                      >
                        {v.message}
                      </Typography>
                      {v.attachments.length ? (
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            ml: 6,
                          }}
                        >
                          {v.attachments.map((v) => {
                            return (
                              <Box
                                key={v.id}
                                sx={{
                                  width: "60px",
                                  height: "60px",
                                  overflow: "hidden",
                                  borderRadius: "10px",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  window.open(
                                    API_URL.baseUrl +
                                      API_URL.supportTicketAttachmentURL +
                                      v.file
                                  )
                                }
                              >
                                <img
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                  }}
                                  src={
                                    API_URL.baseUrl +
                                    API_URL.supportTicketAttachmentURL +
                                    v.file
                                  }
                                />
                              </Box>
                            );
                          })}
                        </Box>
                      ) : null}
                    </Box>
                  );
                })}

                {!rowReply.length ? (
                  <Box
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Typography sx={{ textAlign: "center" }}>
                      No replies added.
                    </Typography>
                  </Box>
                ) : null}
                <div ref={scrollRef} />
              </Box>
              {row?.status == 1 ? (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 1,
                      m: 1,
                    }}
                  >
                    {selectMedia.length > 0
                      ? selectMedia.map((v, index) => {
                          return (
                            <Box
                              sx={{
                                position: "relative",
                              }}
                            >
                              <img
                                src={v.showMedia}
                                style={{
                                  height: "100px",
                                  width: "100px",
                                  borderRadius: "10px",
                                }}
                              />
                              <Tooltip title="Delete">
                                <IconButton
                                  onClick={() => {
                                    const d = selectMedia.filter((e) => e != v);
                                    setSelectMedia(d);
                                  }}
                                  sx={{
                                    position: "absolute",
                                    top: "-5px",
                                    right: "-5px",
                                  }}
                                >
                                  <Delete />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          );
                        })
                      : null}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 1.5,
                      mx: 2,
                      gap: 1,
                    }}
                  >
                    <Paper
                      elevation={10}
                      sx={{
                        flex: 1,
                        pl: 1,
                        borderRadius: "30px",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <IconButton
                        size="small"
                        sx={{
                          m: 1,
                          mb: 0.5,
                          p: 1,
                        }}
                        aria-label="upload picture"
                        component="label"
                      >
                        <input
                          hidden
                          accept="image/*"
                          type="file"
                          onChange={(event) => {
                            if (event.target.files.length) {
                              const d = {};
                              d.type = 1;
                              new Compressor(event.target.files[0], {
                                quality: 0.8,
                                success: (compressedResult) => {
                                  d.id = Date.now();
                                  d.media = compressedResult;

                                  d.showMedia =
                                    URL.createObjectURL(compressedResult);
                                  event.target.value = null;
                                  setSelectMedia((p) => [...p, d]);
                                },
                              });
                            }
                          }}
                        />
                        <Photo sx={{ color: "#c0c0c0", fontSize: "20px" }} />
                      </IconButton>
                      <input
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="Type something"
                        style={{
                          border: "none",
                          outline: "none",
                          flex: 1,
                          paddingRight: "10px",
                        }}
                        onKeyUp={(e) => {
                          if (e.code == "Enter" && e.shiftKey) {
                            sendMessage();
                          }
                        }}
                      />
                    </Paper>

                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <Paper
                        onClick={() => sendMessage()}
                        sx={{
                          borderRadius: "100px",
                          p: 1.5,
                          height: "50px",
                          width: "50px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                        elevation={10}
                      >
                        <Send sx={{ color: "#F8B03F", rotate: "-30deg" }} />
                      </Paper>
                    )}
                  </Box>
                </>
              ) : null}
            </Paper>
          </Box>
        </Box>
      )}

      {/* {open && (
        <SupportRepliesDailog
          open={open}
          handleOnClose={handleOnClose}
          id={id}
        />
      )} */}

      {openConfirmation ? (
        <ConfirmationDailog
          title={row?.status == 1 ? "Close Ticket!" : "Re-Open Ticket!"}
          content={
            `Are you sure want to` +
            (row?.status == 1 ? " close this ticket?" : " re-open this ticket?")
          }
          open={openConfirmation}
          handleOnClose={handleOnCloseConfirmation}
        />
      ) : null}

      {openConfirmC && (
        <CancelDailog
          open={openConfirmC}
          handleOnClose={handleCloseConfirmC}
          title="Close Ticket!"
          content="Are you sure, You want to close this ticket?"
        />
      )}
      {openC && (
        <ConfirmationDailog
          open={openC}
          handleOnClose={handleCloseC}
          content="Are you sure want to done this callback?"
          title="Done Callback!"
        />
      )}
    </Box>
  );
}

export default TicketsDetails;

function RowView({ title, value, width }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "start",
        alignItems: "start",
      }}
    >
      <Typography
        sx={{ mr: 1, fontSize: "16px", fontWeight: "bold", width: { width } }}
        variant="h6"
      >
        {title}
      </Typography>
      <Typography sx={{ fontSize: "16px" }}> : {value}</Typography>
    </Box>
  );
}
